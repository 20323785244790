import React from "react"

import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"
import { useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import StoryHeader from "./StoryHeader/StoryHeader"
import Seo from "~/components/Seo/Seo"
import Timeline, { TimelineProps } from "~/components/Timeline/Timeline"
import { Container, Heading } from "~/components/ui"
import { PrismicStructuredText } from "~/models/PrismicTypes"
import { convertImageDataToChefclubProxy } from "~/utils/PrismicLink"

import css from "./story.module.scss"

interface PropTypes extends TemplateDefaultPropTypes {
  data: StoryQuery
  pageContext: TemplateDefaultPropTypes["pageContext"] & {
    lang: string
  }
}

function StoryPage({ data, ...props }: PropTypes) {
  const intl = useIntl()
  const { pageTitle, buttonLabel, timeline, metaTitle, metaDescription } =
    data.timeline.data
  const storyBottomBg = getImage(data.storyBottomBg)!

  return (
    <>
      <Seo
        title={metaTitle || pageTitle.text}
        description={metaDescription || ""}
        lang={intl.locale}
        location={props.location}
      />

      <StoryHeader title={pageTitle.text} buttonLabel={buttonLabel.text} />

      <Container size="legacy">
        <Heading
          className={css.title}
          variant="extraLarge"
          align="center"
          uppercase
        >
          {timeline.document.data.timelineTitle.text}
        </Heading>
        <br />

        <Timeline {...timeline.document.data} />
      </Container>

      <GatsbyImage
        className={css.bottomCover}
        image={convertImageDataToChefclubProxy(storyBottomBg)}
        alt=""
      />
    </>
  )
}

interface StoryQuery {
  timeline: {
    id: string
    uid: string | null
    data: {
      metaTitle: string | null
      metaDescription: string | null

      pageTitle: PrismicStructuredText
      buttonLabel: PrismicStructuredText
      timeline: {
        document: TimelineProps
      }
    }
  }
  storyBottomBg: FileNode
}

export const query = graphql`
  query OurStoryPage($lang: String) {
    timeline: prismicOurStoryPageV2(lang: { eq: $lang }) {
      uid
      data {
        metaTitle: meta_title
        metaDescription: meta_description

        pageTitle: page_title {
          ...PrismicStructuredText
        }
        buttonLabel: button_label {
          ...PrismicStructuredText
        }
        timeline {
          document {
            ...PrismicTimelineV2
          }
        }
      }
    }

    storyBottomBg: file(
      relativePath: { eq: "Story/banniere_skyline_longue.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 1920, placeholder: BLURRED)
      }
    }
  }
`

export default withTemplate(StoryPage)
